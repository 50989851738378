import { bootstrap } from './actions/bootstrap';
import { lazyInitialize } from './lazyFunctions';

// Precompile and offline SD should be the only synchronously exported method so as not to
// bloat the initial worker load
export { precompileSchema } from './actions/precompileSchema';

// offline session data is needed for boot
export { getOfflineSessionData } from './actions/getOfflineSessionData';
export { saveOfflineSessionData } from './actions/saveOfflineSessionData';

export const initialize: typeof lazyInitialize.importAndExecute = (...args) => {
    // bootstrap should be the VERY first thing called (trust me) (c) Tom
    bootstrap(args[0], args[1].sessionId);
    return lazyInitialize.importAndExecute(...args);
};

export {
    lazyExecute,
    lazyUnsubscribe,
    lazyNotifySubscription,
    lazyInitializeOfflineSync,
    lazyFlushSyncLogs,
    lazyInitializeApplicationSettings,
    lazyInitializeDateTime,
    lazyInitializeFeatureFlags,
    lazyInvokeWin32GqlRequestCallback,
    lazyGetOfflineTimeZoneOffsets,
    lazyGetOfflineTimeZones,
    lazyApplyCalendarEventsSyncLieForRange,
    lazySaveOfflineSettings,
    lazyLoadOfflineSettings,
    lazyInvalidateOfflineConversationNodes,
    lazyLogPeopleDataToSyncLogs,
} from './lazyFunctions';

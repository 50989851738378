import type { AnalyticsCachedSettings, AnalyticsOptions } from 'owa-analytics-types';
import type { StartupAnalyticsInfo } from 'owa-config';
import { logGreyError, logUsage } from 'owa-analytics';
import { setGlobalAnalyticsListeners } from 'owa-analytics/lib/initialization/setGlobalAnalyticsListeners';
import { initializeAnalytics as initializeAnalyticsCore } from 'owa-analytics-core/lib/initializeAnalytics';
import { setCachedSettings } from 'owa-analytics-core/lib/settings/getCachedSettings';
import { processQueueAfterInitilization } from 'owa-analytics-utils';
import { getThreadName } from 'owa-thread-config';
import { trace } from 'owa-trace';

let initializationSucceeded = false;

/**
 * Each worker should register this function with comlink. The main thread is
 * responsible for calling this function to initialize analytics in the web workers.
 */
export async function initializeAnalyticsInWorker(
    analyticsOptions: AnalyticsOptions,
    startupAnalyticsInfo: StartupAnalyticsInfo,
    cachedAnalyticsSettings: AnalyticsCachedSettings
) {
    trace.info(` Initializing Analytics package in ${getThreadName()}.`, 'analytics');

    // Do not use the analytics from inside a worker
    analyticsOptions.useAnalyticsWorker = false;

    try {
        // Set cached analytics settings
        setCachedSettings(cachedAnalyticsSettings);

        analyticsOptions = await initializeAnalyticsCore(analyticsOptions, startupAnalyticsInfo);

        initializationSucceeded = true;

        logUsage('AnalyticsWorkers_WorkerThread_CoreInitialized', {
            worker: getThreadName(),
            result: 'SUCCESS',
        });

        if (!analyticsOptions.requestConfigUpdateTime) {
            trace.info(` Analytics Initialized on ${getThreadName()}.`, 'analytics');

            // Setup global listeners and error handlers - Only call this once!
            setGlobalAnalyticsListeners(analyticsOptions);

            // Process all events captured since the start of the app
            processQueueAfterInitilization();

            trace.info(` Completed Analytics Initialization on ${getThreadName()}.`, 'analytics');
        } else {
            trace.info(
                ` Skipped setting global listeners and processing queue because analytics are already initialized on ${getThreadName()}.`,
                'analytics'
            );
        }
    } catch (e) {
        if (!initializationSucceeded) {
            // If the analytics initialization failed in the worker, we need to report it to the main thread.
            // The main thread will then log the error.
            throw e;
        }

        logGreyError('AnalyticsWorkers_WorkerThread_CoreIntialized', e, {
            worker: getThreadName(),
            result: 'ERROR',
        });

        trace.warn(
            ` The Analytics initialization failed on the ${getThreadName()} thread.`,
            'analytics'
        );
    }
}

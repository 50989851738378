import type { DataWorkerClient } from 'owa-data-worker-utils';
import { expose } from 'comlink';
import {
    initialize,
    precompileSchema,
    getOfflineSessionData,
    saveOfflineSessionData,
    lazyFlushSyncLogs,
    lazyExecute,
    lazyApplyCalendarEventsSyncLieForRange,
    lazyUnsubscribe,
    lazyNotifySubscription,
    lazyInvokeWin32GqlRequestCallback,
    lazyInitializeDateTime,
    lazyInitializeApplicationSettings,
    lazyInitializeFeatureFlags,
    lazyInitializeOfflineSync,
    lazyGetOfflineTimeZoneOffsets,
    lazyGetOfflineTimeZones,
    lazyLoadOfflineSettings,
    lazySaveOfflineSettings,
    lazyInvalidateOfflineConversationNodes,
    lazyLogPeopleDataToSyncLogs,
} from 'owa-data-worker';
import { setDisableAllRequests } from './actions/setDisableAllRequests';
import {
    initializeAnalyticsInWorker,
    updateAnalyticsCachesInWorker,
} from 'owa-worker-analytics-initialization';

const client: DataWorkerClient = {
    initialize,
    initializeOfflineSync: lazyInitializeOfflineSync.importAndExecute,
    execute: lazyExecute.importAndExecute,
    unsubscribe: lazyUnsubscribe.importAndExecute,
    setDisableAllRequests,
    notifySubscription: lazyNotifySubscription.importAndExecute,
    flushSyncLogs: lazyFlushSyncLogs.importAndExecute,
    initializeDateTime: lazyInitializeDateTime.importAndExecute,
    initializeAnalyticsInWorker,
    updateAnalyticsCachesInWorker,
    initializeApplicationSettings: lazyInitializeApplicationSettings.importAndExecute,
    initializeFeatureFlags: lazyInitializeFeatureFlags.importAndExecute,
    precompileSchema,
    invokeWin32GqlRequestCallback: lazyInvokeWin32GqlRequestCallback.importAndExecute,
    getOfflineSessionData,
    saveOfflineSessionData,
    getOfflineTimeZones: lazyGetOfflineTimeZones.importAndExecute,
    getOfflineTimeZoneOffsets: lazyGetOfflineTimeZoneOffsets.importAndExecute,
    applyCalendarEventsSyncLieForRange: lazyApplyCalendarEventsSyncLieForRange.importAndExecute,
    loadOfflineSettings: lazyLoadOfflineSettings.importAndExecute,
    saveOfflineSettings: lazySaveOfflineSettings.importAndExecute,
    invalidateOfflineConversationNodes: lazyInvalidateOfflineConversationNodes.importAndExecute,
    logPeopleDataToSyncLogs: lazyLogPeopleDataToSyncLogs.importAndExecute,
};

expose(client);

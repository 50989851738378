/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie, { type PromiseExtended, type DexieOptions } from 'dexie';
import { logUsage } from 'owa-analytics';
import { deleteDatabase } from 'owa-database-manager/lib/utils/deleteDatabase';
import { addInfoToErrors } from 'owa-database-utils/lib/addInfoToErrors';
import { enforceVersioning } from 'owa-database-utils/lib/enforceVersioning';
import { initializeTransactionMeasurement } from 'owa-database-utils/lib/initializeTransactionMeasurement';
import { TimeoutSlowOperations } from 'owa-database-utils/lib/TimeoutSlowOperations';
import {
    areStorageBucketsAvailable,
    getDexieOptionsForStorageBucket,
    doesDatabaseExistInStorageBucket,
    deleteDatabaseFromStorageBucket,
} from 'owa-storage-buckets';
import { areFeatureFlagsInitialized, isFeatureEnabled } from 'owa-feature-flags';
import { syncLogsSchema, type SyncLogsTable } from 'owa-offline-sync-logging-schema';
import { workerLeaderSchema, type WorkerLeaderTable } from 'owa-offline-worker-leader-schema';
import { trace } from 'owa-trace';

const DATABASE_NAME = 'owa-offline-system-data';

// Put the system database in its own bucket to isolate from all other activity.
const BUCKET_NAME = DATABASE_NAME;

export class SystemDatabase extends Dexie {
    public readonly workerLeader!: WorkerLeaderTable;
    public readonly syncLogs!: SyncLogsTable;

    constructor(name: string, options?: DexieOptions | null) {
        super(name, {
            chromeTransactionDurability: 'relaxed',
            ...options,
        });

        const store = {
            ...workerLeaderSchema,
        };
        this.version(1).stores(store);
        this.version(2).stores({ ...syncLogsSchema });

        addInfoToErrors(this);
        enforceVersioning(this, options);
        initializeTransactionMeasurement(this);

        if (isFeatureEnabled('fwk-idb-timeout3')) {
            this.use({
                stack: 'dbcore',
                name: TimeoutSlowOperations.name,
                create: TimeoutSlowOperations,
            });
        }
    }
}

let database: PromiseExtended<SystemDatabase> | null = null;
export async function initializeSystemDatabase() {
    const options = shouldUseStorageBucket()
        ? await getDexieOptionsForStorageBucket(BUCKET_NAME)
        : null;
    database = new SystemDatabase(DATABASE_NAME, options).open() as PromiseExtended<SystemDatabase>;
    deleteLegacySystemDatabase();
    return database;
}

// If we have started using storage buckets, delete the previous system database
function deleteLegacySystemDatabase() {
    return shouldUseStorageBucket() ? Dexie.delete(DATABASE_NAME) : Promise.resolve();
}

export function getSystemDatabase(): Promise<SystemDatabase> {
    if (!database) {
        logUsage('SystemDatabaseIsNotInitializedYet');
        return initializeSystemDatabase();
    }

    return database;
}

export function deleteSystemDatabase(traceMessage: string): Promise<void> {
    trace.info(`System Database: ${traceMessage}`);
    return shouldUseStorageBucket()
        ? deleteDatabaseFromStorageBucket(BUCKET_NAME, DATABASE_NAME)
        : deleteDatabase(DATABASE_NAME);
}

function doesLegacyDbExist(): Promise<boolean> {
    return Dexie.exists(DATABASE_NAME);
}

function doesBucketDbExist(): Promise<boolean> {
    return doesDatabaseExistInStorageBucket(BUCKET_NAME, DATABASE_NAME);
}

export async function doesSystemDatabaseExist(): Promise<boolean> {
    if (!areFeatureFlagsInitialized()) {
        const [exists, existsLegacy] = await Promise.all([
            doesBucketDbExist(),
            doesLegacyDbExist(),
        ]);
        return exists || existsLegacy;
    }
    return shouldUseStorageBucket() ? doesBucketDbExist() : doesLegacyDbExist();
}

function shouldUseStorageBucket(): boolean {
    return areStorageBucketsAvailable() && isFeatureEnabled('fwk-system-db-bucket');
}

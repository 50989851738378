import { bootstrap } from './bootstrap';
import { lazyGetOfflineSessionData } from 'owa-offline-session-data';
import type { SessionDataParams } from 'owa-shared-startupdata/lib/types/SessionDataParams';
import type { WindowBootstrapSettings } from 'owa-worker-types';
import type { MailboxInfo } from 'owa-client-types';

// async to not throw to the main thread if bootstrap fails
export async function getOfflineSessionData(
    rawData: WindowBootstrapSettings,
    app: string,
    databaseId: string,
    mailboxInfo: MailboxInfo,
    sessionDataParams: SessionDataParams
) {
    // need to call bootstrap, otherwise lazy loads will fail
    bootstrap(rawData);

    return lazyGetOfflineSessionData.importAndExecute(
        app,
        databaseId,
        mailboxInfo,
        sessionDataParams
    );
}

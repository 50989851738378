import type { UpdateAnalyticsCachesInWorker } from 'owa-analytics-types';
import { updateCommonPropertiesCache } from 'owa-analytics-core/lib/settings/getCommonProperties';
import { updateFlightsAndApplicationSettingsCache } from 'owa-analytics-core/lib/settings/getAnalyticsFlightsAndAppSettings';
import { updatePartialCachedSettings } from 'owa-analytics-core/lib/settings/getCachedSettings';
import { trace } from 'owa-trace';
import { logGreyError } from 'owa-analytics';
import { registerAnalyticsAddon } from 'owa-analytics-addons';

const EVENT_NAME = 'AnalyticsWorkers_WorkerThread_CacheUpdate';

type GetUpdateCachesCb = () => Promise<UpdateAnalyticsCachesInWorker>;

let getUpdateCachesFunction: GetUpdateCachesCb | null = null;

export function updateAnalyticsCachesInWorker(
    cachesToUpdate: UpdateAnalyticsCachesInWorker,
    getUpdateCaches?: GetUpdateCachesCb
) {
    // Set the Comlink callbacks function to get the updated caches
    if (getUpdateCaches) {
        getUpdateCachesFunction = getUpdateCaches;
    }

    // Set the callback for OneDSWrapper to trigger the cache update from the main thread
    // updateCachesFromMainThread.cb = getAndUpdateAnalyticsCachesInWorker;
    registerAnalyticsAddon(
        'GetAndUpdateAnalyticsCachesInWorker',
        () => getAndUpdateAnalyticsCachesInWorker
    );

    // Update the FlightsAndApplicationSettings Cache
    if (cachesToUpdate.FlightsAndApplicationSettingsCache) {
        updateFlightsAndApplicationSettingsCache(cachesToUpdate.FlightsAndApplicationSettingsCache);
    }

    // Update the UserConfiguration Cache
    if (cachesToUpdate.UserConfigurationCache) {
        updatePartialCachedSettings({ userConfiguration: cachesToUpdate.UserConfigurationCache });
    }

    // Update the CommonProperties Cache
    if (cachesToUpdate.CommonPropertiesCache) {
        updateCommonPropertiesCache(cachesToUpdate.CommonPropertiesCache);
    }
}

async function getAndUpdateAnalyticsCachesInWorker() {
    if (!getUpdateCachesFunction) {
        trace.warn(` ${EVENT_NAME} (re-initialization)`);
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logGreyError(EVENT_NAME, new Error('getUpdateCachesFunction is null'));
        return;
    }

    const cachesToUpdate = await getUpdateCachesFunction();
    updateAnalyticsCachesInWorker(cachesToUpdate);
    trace.info(` ${EVENT_NAME}: Update completed`, 'analytics');
}

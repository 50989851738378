/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie, { type DexieOptions } from 'dexie';
import type { TraceErrorObject } from 'owa-trace';

const failedDatabaseNames = new Set<string>();

export function enforceVersioningInternal(
    database: Dexie,
    options?: DexieOptions | null,
    logError?: (name: string, error: Error) => void
) {
    database.open = Dexie.override(database.open, originalFn => {
        return async () => {
            try {
                await checkExistingVersion(database, options);
            } catch (error) {
                if (!failedDatabaseNames.has(database.name) && logError) {
                    logError('EnforceVersioning', error);
                    failedDatabaseNames.add(database.name);
                }
                throw error;
            }

            return originalFn.apply(database);
        };
    });
}

export async function checkExistingVersion(database: Dexie, options?: DexieOptions | null) {
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * need to use indexedDB directly to work around deficiencies in Dexie version enforcement */
    const indexedDBToUse = (options?.indexedDB as IDBFactory) || undefined || self.indexedDB;
    const info = (await indexedDBToUse.databases()).find(db => db.name === database.name);
    if (info && info.version && info.version >= (database.verno + 1) * 10) {
        const error = new Error('VersionError') as TraceErrorObject;
        error.additionalInfo = {
            version: info.version,
            expectedVersion: database.verno * 10,
            name: database.name,
        };
        throw error;
    }
}

import type { TraceErrorObject } from 'owa-trace';
import { getStorageBucket } from './getStorageBucket';

export async function deleteDatabaseFromStorageBucket(
    bucketName: string,
    dbName: string
): Promise<void> {
    const bucket = await getStorageBucket(bucketName);
    if (!bucket) {
        return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * need to use indexedDB directly since Dexie.delete doesn't work with storage buckets */
        const req = bucket.indexedDB.deleteDatabase(dbName);
        req.onerror = () => {
            const error = new Error('Failed to delete database from bucket') as TraceErrorObject;
            error.additionalInfo = { bucketName, dbName };
            reject(error);
        };

        req.onsuccess = () => resolve();
    });
}

import type { DexieOptions } from 'dexie';
import type { StorageBucketOptions } from './StorageBucket';
import { getStorageBucket } from './getStorageBucket';

export async function getDexieOptionsForStorageBucket(
    bucketName: string,
    options: StorageBucketOptions = { persisted: true, durability: 'relaxed' }
): Promise<DexieOptions | null> {
    const bucket = await getStorageBucket(bucketName, options);
    if (!bucket) {
        return null;
    }

    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * need to pass indexedDB to Dexie ctor to work-around missing support for storage buckets */
    return { indexedDB: bucket.indexedDB };
}

import type {
    NavigatorWithStorageBuckets,
    StorageBucketOptions,
    StorageBucket,
} from './StorageBucket';

export async function getStorageBucket(
    name: string,
    options: StorageBucketOptions = { persisted: true, durability: 'relaxed' }
): Promise<StorageBucket | null> {
    const nav = navigator as NavigatorWithStorageBuckets;

    if (!nav.storageBuckets?.open) {
        return null;
    }

    return nav.storageBuckets.open(name, options);
}

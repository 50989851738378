import { getStorageBucket } from './getStorageBucket';

export async function doesDatabaseExistInStorageBucket(
    bucketName: string,
    dbName: string
): Promise<boolean> {
    const bucket = await getStorageBucket(bucketName);
    if (!bucket) {
        return false;
    }

    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * need to use indexedDB directly since Dexie.exists doesn't work with storage buckets */
    const databases = await bucket.indexedDB.databases();
    return databases.some(db => db.name === dbName);
}
